@import '../../../styles/variables.scss';
#app-layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $black;

  .page {
    margin: auto;
    max-width: 1440px;
    overflow: hidden;

    .page-content {
      display: flex;
    }
  }
}
