@import 'styles/bootstrap-grid.min.css';
@import 'styles/form';
@import 'styles/modal';
@import 'styles/table';
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700&display=swap');

body {
  @extend .form;
  @extend .modal;
  @extend .table;
  margin: 0;
  font-family:'JetBrains Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

#app {

  .app-loading {
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
}
